@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.gridItem{
  width: 30rem;
  height: 22rem;
  background: blue;
}

.lagos{
  width: 30rem;
  height: 22rem;
  background-image: url(../assests/images/lagos.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.5em;
  display: grid;
  place-content: center;
  transition: transform 1s;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1),
              -2px -2px 5px rgba(0, 0, 0, 0.1);
}

.lagos:hover{
  transform: scale(1.04);
}

.abuja{
  width: 30rem;
  height: 22rem;
  background-image: url(../assests/images/abuja.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.5em;
  display: grid;
  place-content: center;
  transition: transform 1s;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1),
              -2px -2px 5px rgba(0, 0, 0, 0.1);
}

.abuja:hover{
  transform: scale(1.04);
}
.ph{
  width: 30rem;
  height: 22rem;
  background-image: url(../assests/images/ph.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.5em;
  display: grid;
  place-content: center;
  transition: transform 1s;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1),
              -2px -2px 5px rgba(0, 0, 0, 0.1);
}

.ph:hover{
  transform: scale(1.04);
}
.ibadan{
  width: 30rem;
  height: 22rem;
  background-image: url(../assests/images/ibadan.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.5em;
  display: grid;
  place-content: center;
  transition: transform 1s;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1),
              -2px -2px 5px rgba(0, 0, 0, 0.1);
}

.ibadan:hover{
  transform: scale(1.04);
}
.benin{
  width: 30rem;
  height: 22rem;
  background-image: url(../assests/images/benin.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.5em;
  display: grid;
  place-content: center;
  transition: transform 1s;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1),
              -2px -2px 5px rgba(0, 0, 0, 0.1);
}

.benin:hover{
  transform: scale(1.04);
}
.calabar{
  width: 30rem;
  height: 22rem;
  background-image: url(../assests/images/calaber.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.5em;
  display: grid;
  place-content: center;
  transition: transform 1s;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1),
              -2px -2px 5px rgba(0, 0, 0, 0.1);
}

.calabar:hover{
  transform: scale(1.04);
}

.gridContainer{
  display: flex;
  column-gap: 2.5em;
  width:100%;
}

.mainContainer{
  display: flex;
  margin: 2em auto;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items:center; */
  width: 60%;
  gap: 1em;
}

.link{
  color: white;
  font-size: 1.7em;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 800;
}

.line{
  width: 40px;
  height: 10px;
  margin: 0 auto;
  border-bottom: 2px solid white;
}

.line:hover{
  width: 55px;
}

.title > h1{
  font-size: 2em;
  margin-bottom: -0.5em;
  font-family: 'Poppins', sans-serif;
}

@media (max-width: 767px) {
  .mainContainer{
    width: 95%;
    margin: 4em auto 0;
    padding: 0.4em;
  }
  .gridContainer{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }

  .lagos{
    width: 90%
  }
  .abuja{
    width: 90%
  }
  .ph{
    width: 90%
  }
  .ibadan{
    width: 90%
  }
  .calabar{
    width: 90%
  }
  .benin{
    width: 90%
  }

  
}