.navbar {
  width: 30vw;
  height: 100vh;
  padding: 2em;
  display: flex;
  flex-direction: column;
  background-color: var(--mantine-color-blue-filled);
}

.logo {
  display: none;
}

.vendor {
  background-color: orange;
  border: none;
  outline: none;
  padding: 0.7em;
  margin: 0 auto;
  /* height: 2.5em; */
  color: white;
  font-size: 1em;
  font-weight: bolder;
  border-radius: 0.3em;
  cursor: pointer;
}

@media (max-width: 768px) {

  /* Apply the following styles for screens wider than 768px (adjust the breakpoint as needed) */
  .navbar {
    width: 40%;
    height: 100vh;
  }
}

/* Rest of the styles remain the same */
.navbarMain {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
}

.header {
  padding-bottom: var(--mantine-spacing-md);
  margin-bottom: calc(var(--mantine-spacing-md) * 1.5);
  border-bottom: rem(1px) solid var(--mantine-color-blue-7);
}

.footer {
  width: 100%;
  padding-top: var(--mantine-spacing-md);
  margin-top: var(--mantine-spacing-md);
  border-top: rem(1px) solid var(--mantine-color-blue-7);
}

.version {
  background-color: var(--mantine-color-blue-7);
  color: var(--mantine-color-white);
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-white);
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
  border-radius: var(--mantine-radius-sm);
  font-weight: 500;
  /* width: 100%; */

  /* @mixin hover {
    background-color: var(--mantine-color-blue-7);
    color: var(--mantine-color-white);

    .linkIcon {
      color: var(--mantine-color-white);
    }
  } */

  &[data-active] {

    &,
    &:hover {
      box-shadow: var(--mantine-shadow-sm);
      background-color: var(--mantine-color-white);
      color: var(--mantine-color-blue-filled);

      .linkIcon {
        color: var(--mantine-color-blue-filled);
      }
    }
  }
}

.linkIcon {
  color: var(--mantine-color-blue-1);
  margin-right: var(--mantine-spacing-sm);
  /* width: rem(25px);
  height: rem(25px); */
}

/* Styles for mobile devices (max-width: 767px) */
@media (max-width: 767px) {
  .navbar {
    display: none;
  }


  .link {
    font-size: var(--mantine-font-size-xl);
    color: var(--mantine-color-blue-7);
    font-weight: 500;

    .linkIcon {
      color: var(--mantine-color-blue-filled);
    }
  }

  .vendor {
    margin: 0.7em 1.5em;
  }
}