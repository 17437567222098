@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.section{
    width: 100%;
    z-index: 500;
    margin: 2.5em 4em;
    gap: 0.5em;
    display: flex;
    flex-direction: column;
}

.heading{
    font-size: 2em;
    font-family: 'Poppins', sans-serif;
    color: var(--mantine-color-blue-filled);
}

.card {
    position: relative;
    width: 18vw;
    height: fit-content;
  }
  
  .title {
    display: block;
    text-transform: capitalize;
    color: black;
    text-decoration: none;
    margin-top: var(--mantine-spacing-md);
    margin-bottom: rem(5px);
  }
  
  .title:hover{
    color: #228BE6;
  }
  
  .price{
    font-size: 1.2em;
  }
  
  .price > span{
    font-weight: 900;
    font-size: 1.2em;
  }
  
  .action {
    background-color: transparent;
    cursor: pointer;
  }
  
  .action:hover{
    background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-6));
  }
  
  .footer {
    margin-top: var(--mantine-spacing-sm);
  }
  
  .address{
    text-transform: capitalize;
    color: red;
  }
  .container{
    width: 60%;
    margin: 3em auto;
    display: flex;
    flex-direction: column;
  }
  
  
  
  h1{
    font-size: 2em;
    margin-bottom: -0.5em;
    font-family: 'Poppins', sans-serif;
  }
  
  @media (max-width: 767px) {
    .card{
      width: 96vw;
    }
    .section{
        width: 100vw;
        margin: 2.5em 0 1em -1.2em;
        height:fit-content;
    }
    }

  /* @media (max-width: 1024px) {
    .card{
      width: 100%;
    }
    } */