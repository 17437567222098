.footer {
    margin-top: rem(120px);
    padding-top: calc(var(--mantine-spacing-xl) * 2);
    padding-bottom: calc(var(--mantine-spacing-xl) * 2);
    background-color: rgba(0, 0, 0, 0.08);
    border-top: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
  }
  
  .logo {
    max-width: rem(200px);
    
  
    @media (max-width: 48em) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
  .description {
    margin-top: rem(5px);
    font-style: oblique;
  
    @media (max-width: 48em) {
      margin-top: var(--mantine-spacing-xs);
      text-align: center;
    }
  }
  
  .inner {
    display: flex;
    justify-content: space-between;
  
    @media (max-width: 48em) {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .groups {
    display: flex;
    flex-wrap: wrap;
    gap: 5em;
  
    @media (max-width: 48em) {
      display: none;
    }
  }
  
  .wrapper {
    width: rem(160px);
  }
  
  .link {
    display: block;
    color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-1));
    font-size: var(--mantine-font-size-sm);
    padding-top: rem(3px);
    padding-bottom: rem(3px);
  
    &:hover {
      text-decoration: underline;
    }
  }
  
  .title {
    font-size: var(--mantine-font-size-lg);
    font-weight: 900;
    font-family: Greycliff CF, var(--mantine-font-family);
    margin-bottom: calc(var(--mantine-spacing-xs) / 2);
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  }
  
  .afterFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--mantine-spacing-xl);
    padding-top: var(--mantine-spacing-xl);
    padding-bottom: var(--mantine-spacing-xl);
    border-top: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4));
  
    @media (max-width: 48em) {
      flex-direction: column;
    }
  }
  
  .social {
    @media (max-width: 48em) {
      margin-top: var(--mantine-spacing-xs);
    }
  }