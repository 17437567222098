.check {
    margin-top: 50px;
    width: 300px;
    z-index: 1;
    position: relative;
}

.ker {
    width: 300px;
    margin: 0;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3em;
}
.tep {
    font-size: 0.6em;
}


@media (max-width: 767px) {
    .ker{
        width:100%;
    }
}