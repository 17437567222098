.abdul {
    background-color: whitesmoke;
    width: 100%;
    height: max-content;
    justify-content: center;
    margin: 0 auto;
}
.tin {
    width: 30%;
    margin-left: 1%;
}