.line {
    margin-top: -60px;
}
.first {
    width: fit-content;
    height: fit-content;
    background-image:  url(https://images.unsplash.com/photo-1611892440504-42a792e24d32?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWwlMjByb29tfGVufDB8fDB8fHww);
}
.crv {
    width: fit-content;
}
.ero {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55vh;
    margin-top: -70px;
    /* background-image: url(../assests/images/hotelone.jpg); */
    width: 100%;
    object-fit: cover;
    /* position: relative; */
    background-size: cover;
    background-position: center;
}
.hotelrom {
    margin-left: 21%;
}

/* .descrip {

} */
.descrip p {
    text-align: center;
}
.ent {
    text-align: center;
}
.tol {
    display: flex;
    width: 50%;
    height:100px;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    background-color: whitesmoke;
}
.maindiv {
    display: flex;
    margin: auto;
    width: 60%;
    height: fit-content;
    background-color: whitesmoke;
}
.seconcol {
    display: flex;
    margin: 20px;
    flex-direction: column;
    width: 30%;
}
.thirdcol {
    margin: 20px;
    text-align: left;
    width: 30%;
    align-items: center;
}
.ncol {
    display: flex;
    margin: auto;
}
.con {
    display: flex;
    gap: 10px;
}
.lon {
    display: flex;
    gap: 20px;
}
.booknow {
    display: flex;
    justify-content: space-around;
    width: 60%;
    text-align: left;
    height: 100px;
    background-color: whitesmoke;
    margin: auto;
}
.totalroom {
    width: 50%;
    text-align: left;
    margin-left: 10px;
}
.cost {
    width: 50%;
    margin-left: 10px;
}
@media (max-width: 780px) {
    .hotelrom {
        margin: 0 auto;
        font-size: large;
        margin-left: 5%;
    }
    .ero {
        width: 390px;
        margin: 0 auto;
    }
    .rff {
        
        width: 370px;
        margin: 0 auto;
    }
    .ain {
        width: 370px;
        margin: 0 auto;
    }
    .descrip p {
        text-align: center;
    }
    .descrip {
        width: 370px;
    }
    .tol {
        width: 100%;
        height: 170px;
    }
    .maindiv {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .booknow {
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
    }
}

