@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.section{
    width: 100%;
    z-index: 500;
    margin: 2.5em 4em;
    gap: 0.5em;
    display: flex;
    flex-direction: column;
}

.heading{
    font-size: 2em;
    font-family: 'Poppins', sans-serif;
    color: var(--mantine-color-blue-filled);
}

.card {
    position: relative;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .text{
    display: inline-flex;
    gap: 0.3em;
    justify-content: center;
    align-items: center;
  }

  .textContainer{
    display: flex;
    font-family: 'Poppins', sans-serif;
    gap:0.5em;
    padding-bottom: 0.2em;
  }

  .value{
    font-size: 1.3em;
    font-weight: bolder;
  }
  
  .title {
    display: block;
    text-transform: capitalize;
    color: black;
    text-decoration: none;
    margin-top: var(--mantine-spacing-md);
    margin-bottom: rem(5px);
  }
  
  .title:hover{
    color: #228BE6;
  }
  
  .price{
    font-size: 1.2em;
  }
  
  .price > span{
    font-weight: 900;
    font-size: 1.2em;
  }
  
  .action {
    background-color: transparent;
    cursor: pointer;
  }
  
  .action:hover{
    background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-6));
  }
  
  .footer {
    margin-top: var(--mantine-spacing-sm);
  }
  
  .address{
    text-transform: capitalize;
    color: red;
  }
  .container{
    width: 60%;
    margin: 3em auto;
    display: flex;
    flex-direction: column;
  }
  
  
  
  h1{
    font-size: 2em;
    margin-bottom: -0.5em;
    font-family: 'Poppins', sans-serif;
  }

  .item{
    display: flex;
    gap: 1.3em;
    width: 70%;
    height: auto;
  }

  .imgContainer{
    width: 50%;
  }

  .img{
    width: 100%;
    object-fit: contain;
    }

  .content{
    width: 40%;
    margin: auto;
  }
    .dropzone {
      border: 2px dashed rgba(0, 0, 0, 0.05);
      width: 100%;
      padding: 1em;
      height: fit-content;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 0.5em;
      cursor: pointer;
    }
  @media (max-width: 767px) {
    .card{
      width: 96vw;
      flex-direction: column;
    }
    .section{
        width: 100vw;
        margin: 2.5em 0 1em -1.2em;
        height:fit-content;
    }

    .item{
      width: 100%;
      flex-direction: column;
    }

    .imgContainer{
      width: 100%;
    }
    }

  /* @media (max-width: 1024px) {
    .card{
      width: 100%;
    }
    } */