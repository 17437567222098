.card-group {
    display: flex;
    margin: 0 auto;
    gap: 20px;
    justify-content: center;
}
.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 270px;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .container {
    padding: 2px 16px;
  }
  .Firsthotel {
    width: 290px;
    height: 240px;
  }

  @media (max-width: 780px) {
    .Firsthotel {
        width: 410px;
        height: 300px;
    }
  }