  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.header {
    height: rem(60px);
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }
  
  .link {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    text-decoration: none;
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-size: 0.9rem;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
}

.link:hover{
  background-color: #f8f8f8;
}

.btn{
    color: var(--mantine-color-white);
    text-decoration: none;
    background: #228BE6;
    padding-left: var(--mantine-spacing-sm);
    padding-right: var(--mantine-spacing-sm);
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-weight: 500;
    border-radius: 0.3em;
    font-size: var(--mantine-font-size-sm);
    text-align: center;
}

.btnDefault {
    color: #228BE6;
    text-decoration: none;
    background: var(--mantine-color-white);
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-weight: 500;
    border-radius: 0.3em;
    border: 1px solid var(--mantine-color-gray-4);
    font-size: var(--mantine-font-size-sm);
    text-align:center;
}
  
  .subLink {
    width: 100%;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
    border-radius: var(--mantine-radius-md);
  
    /* @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
    } */
  }
  
  .dropdownFooter {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
    margin: calc(var(--mantine-spacing-md) * -1);
    margin-top: var(--mantine-spacing-sm);
    padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-md) * 2);
    padding-bottom: var(--mantine-spacing-xl);
    border-top: rem(1px) solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
  }

  .tabLink{
    color: black;
    /* font-weight: bold; */
    text-decoration: none;
  }