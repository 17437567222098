  @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    /* grid-template-rows: 80px 200px; */
    gap: 10px;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    height: fit-content;
    width: fit-content;
    margin: 0 auto;
    border-radius: 5px;
  }
  
  .grid-container > div {
    text-align: center;
    font-size: 30px;
    width: 300px;
    border-radius: 5px;
  }
  .where {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    gap: 3px;
    /* background-color: #228BE6; */
    border-radius: 3px;
    width: 500px;
    height: 50px;
    margin-left: -112px;
    color: white;
  }
  .fir {
    background-color: #0d385e !important;
    padding-left: 10px;
    text-align: left;
    width: 250px;
    border-radius: 3px;
    margin-top: 14px;
  }
  .sec {
    background-color: #228BE6;
    padding-left: 10px;
    width: 250px;
    border-radius: 3px;
    margin-top: 14px;
  }

  
  .num4 {
    width: 180px !important;
    height: 100%;
  } 

  .select{
    font-size: 0.6em;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.1em;
    text-align: left;
    border: transparent;
    outline:transparent;
  }

  .select > p {
    color: #228BE6;
    font-size: 0.5em;
    text-align: left;
    font-weight: bold;
    display: flex;
    gap:0.2em;
    margin-bottom: -0.05em;
    font-family: 'Poppins', sans-serif;
  }


  .pl {
    color: white;
    font-size: 4em;
    margin-bottom: -0.4em;
    font-family: 'Poppins', sans-serif;
}

.hero >div > p{
  color: white;
  font-family: 'Poppins', sans-serif;
}

.nput {
    text-align: center;
    font-size: 20px;
    width: 300px;
    height: 30px;
    border-radius: 5px;
    border: transparent;
    outline: transparent;
}
.hero {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55vh;
    margin-top: -70px;
    background-image: url(../assests/images/mp-fV2dM2WvKvE-unsplash.jpg);
    /* position: relative; */
    background-size: cover;
    background-position: center;
  }

  .hero > div{
    width: 60%;
  }

  .tnn {
    width: 180px;
    height: 100%;
    font-size: 24px;
    color: white;
    background-color: #228BE6;
    border-radius: 5px;
    border: transparent;
    outline: transparent;
    font-family: 'Poppins', sans-serif;
  }
  /* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 780px) {
    .grid-container {
        grid-template-columns: auto;
        width: 85vw;
        margin-left: -3em;
        padding: 0.5em;
        /* margin: auto; */
        gap: -2em;
        border-radius: 5px;
        
    }
    .grid-container > div {
        width: 100%;
        /* margin-left: -2em; */
        background-color: whitesmoke;
        box-shadow: 2px 2px 5px whitesmoke
                    -2px -2px 5px rgba(0, 0, 0, 0.5);
    }
    .pl {
        font-size: 2em;
        margin-bottom: -0.7em;
        margin-left: -1em;
    }
    .hero {
        width: 100%;
        height: 80vh;
        padding-top: 8em;
    }
    .nput {
        width: 250px;
        margin: 0 auto ;
    }

    .tnn {
      font-size: 0.7em;
    } 
    .hero >div > p{
      margin-left: -2em;
    }
    .where {
      width: fit-content;
      height: fit-content;
      display: flex;
      flex-direction: column-reverse;
      margin-left: -48px;
      gap: 0;
    }
    .fir {
      width: 300px;
    }
    .sec {
      width: fit-content !important;
      margin-bottom: -10px;
    }
  }

  @media (min-width: 1020px) {
    .grid-container {
      margin-left: -10%;
    }
    .pl {
      margin-left: -10%;
    }
    .hero >div > p{
      margin-left: -10%;
    }
  }