@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.gridContainer {
    display: flex;
    flex-direction: column;
    /* column-gap: 2.5em; */
    gap: 2em;
    padding-bottom: 5em;
    width: 100%;
}

.mainContainer {
    display: flex;
    margin: 2em auto;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items:center; */
    width: 60%;
    gap: 1em;
}

.title{
    text-align: center;
}

.title>h1 {
    font-size: 2em;
    margin-bottom: -0.5em;
    /* text-align: center; */
    font-family: 'Poppins', sans-serif;
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: fit-content;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    /* background-color: red; */
}

.contentContainer {
    display: flex;
}

.content {
    margin-left: 10px;
}

/* Assuming the image is a direct child of the container */
.img img {
    height: 10em;
    width: 10em;
    object-fit: cover;
    border-radius: 10px;
}

.btn {
    /* padding: 2em 2em; */
    margin: 1em;
}

@media (max-width: 767px) {
    .mainContainer {
        width: 95%;
        margin: 4em auto 0;
        padding: 0.4em;
    }

    .gridContainer {
        flex-direction: column;
        gap: 1em;
    }

    .contentContainer {
        flex-direction: column;
    }

    .item {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .img {
        display: grid;
        place-items: center;
    }

    .img img {
        width: 20em;
        height: fit-content;
    } 

    .content {
        display: grid;
        place-items: center;
    }

    .content>div {
        text-align: center;
    }
}