@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.section{
    height: 200px;
    width: 100vw;
    z-index: 500;
    margin: 2.5em 4em;
    gap: 0.5em;
    display: flex;
    flex-direction: column;
}

.heading{
    font-size: 2em;
    font-family: 'Poppins', sans-serif;
    color: var(--mantine-color-blue-filled);
}

.name{
    width: 100%;
}

.grid{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 5em;
}

.grid > div{
    width: 47%;
}

.grid >div > .name{
    margin-top:1em;
}

.grid >div > .name{
    margin-top:1em;
}
.grid >div > h2{
    font-family: 'Poppins', sans-serif;
}

.btn{
    color: var(--mantine-color-white);
    text-decoration: none;
    background: #228BE6;
    padding-left: var(--mantine-spacing-sm);
    padding-right: var(--mantine-spacing-sm);
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-weight: 500;
    border-radius: 0.3em;
    font-size: var(--mantine-font-size-sm);
    text-align: center;
    width: 20%;
    border: none;
    cursor: pointer;
}

.btn:hover{
    background-color: #58a2de;
}

.innerGrid{
    display: flex;
    margin-top:1em;
    justify-content: space-between;
}

.innerGrid > .name{
    width: 47%;
}

.info{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: rgba(0, 0, 0, 0.8);
}

.info > .icon{
    color: rgb(212, 38, 38);
}

@media (max-width: 767px) {
    .section{
        width: 100%;
        margin: 2.5em 0 1em -1.2em;
        height:fit-content;
        overflow-x: hidden;
    }

    .grid{
        margin-bottom: 2em;
        flex-direction: column;
    }

    .grid > div{
        width: 90%;
    }

    .btn{
        width: 80%;
        margin: 0 auto;
    }
}