.card {
  position: relative;
  /* background-color: var(--mantine-color-body); */
  background-color: green;
  width: 18vw;
  height: 23em;
}

.title {
  display: block;
  text-transform: capitalize;
  color: black;
  text-decoration: none;
  margin-top: var(--mantine-spacing-md);
  margin-bottom: rem(5px);
}

.title:hover{
  color: #228BE6;
}

.price{
  font-size: 1.2em;
}

.price > span{
  font-weight: 900;
  font-size: 1.2em;
}

.action {
  background-color: transparent;
  cursor: pointer;
}

.action:hover{
  background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-6));
}

.footer {
  margin-top: var(--mantine-spacing-md);
}

.address{
  text-transform: capitalize;
}
.container{
  width: 60%;
  margin: 3em auto;
  display: flex;
  flex-direction: column;
}

.container > .section{
  margin-bottom: 2em;
  width: 100%;
}



h1{
  font-size: 2em;
  margin-bottom: -0.5em;
  font-family: 'Poppins', sans-serif;
}

.error{
  color: red;
  width: 100%;
  /* background-color: red; */
}

@media (max-width: 767px) {
  .container{
    width: 80%;
  }

  .container > .section{
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .card{
    width: 95%;
    height: 28em;
  }
  }